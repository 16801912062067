export class AuthenticationError extends Error {
    __proto__:any;

    constructor(m: string) {
        super(m);
        // Set the prototype explicitly.
        this.__proto__ = AuthenticationError.prototype;
    }
}

export class PasswordConfirmationError extends Error {
    __proto__:any;

    constructor(m: string) {
        super(m);
        // Set the prototype explicitly.
        this.__proto__ = PasswordConfirmationError.prototype;
    }
}

export class NoOrganizationError extends Error {
    __proto__:any;

    constructor(m: string) {
        super(m);
        // Set the prototype explicitly.
        this.__proto__ = NoOrganizationError.prototype;
    }
}