<button class="btn-clean btn-collapse" (click)="collapseMenu()">
    <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve">
      <g>
        <circle class="st0" cx="64" cy="64" r="16"/>
        <circle class="st0" cx="64" cy="112" r="16"/>
        <circle class="st0" cx="64" cy="16" r="16"/>
      </g>
    </svg>
</button>    
<ul id="al-sidebar-list" class="al-sidebar-list"
    (mouseleave)="hoverElemTop=outOfArea">
  <a routerLink="/pages/dashboard" class="logo-side clearfix"><img src="../../../../assets/img/logo-branca.png" alt=""></a>
  <div class="user-profile clearfix">
    <div class="dropdown al-user-profile" ngbDropdown>
      <a class="profile-toggle-link" id="user-profile-dd" aria-expanded="false" ngbDropdownToggle>
        <span *ngIf="user" >{{ user.name }}</span>
      </a>
      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" id="account-dropdown">
        <li class="dropdown-item"><a class="signout" [routerLink]="['../change-password']"><i class="fa fa-lock" aria-hidden="true"></i>Trocar senha</a></li>
        <li class="dropdown-item"><a class="signout" (click)="logout();"><i class="fa fa-power-off"></i>Logout</a></li>
      </ul>
    </div>
  </div>
  <ba-menu-item
    [menuItem]="item"
    (itemHover)="hoverItem($event)"
    (onItemClick)="onMenuItemClicked($event)"
    (toggleSubMenu)="toggleSubMenu($event)"
    *ngFor="let item of menuItems"></ba-menu-item>
</ul>
