import { Injectable } from '@angular/core';
import { PermissionsEnum } from '../enums/permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor() { }

  getPermissions(): Array<string> {
    return JSON.parse(localStorage.getItem('user')).permissions;
  }

  hasPermission(permission: string): boolean {
    if(permission){
      return permission === PermissionsEnum.VISIVEL ? true :
              this.getPermissions().find(x => x === permission) ? true : false;
    }
    return false;
  }
}
