import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { User } from '../models/user.model';

@Injectable()
export class UserStorageService {

    private readonly USER = "user";

    constructor(private localStorage: LocalStorageService) {}

    getUser():User{
        let result = JSON.parse(this.localStorage.get(this.USER));
        return new User(result.id, result.name, result.email, result.password, result.password_confirmation, result.permissions);
    }

    removeUser(){
        this.localStorage.remove(this.USER);
    }

    setUser(user:User){
        this.localStorage.set(this.USER, JSON.stringify(user));
    }

}
