import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { BaThemeConfig } from "./theme.config";

import { BaThemeConfigProvider } from "./theme.configProvider";

import {
  BaBackTop,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaPageTop,
  BaSidebar,
} from "./components";

import { LoginService } from "../pages/user/services/login.service";
import { OAuthService } from "../pages/user/services/oauth.service";
import { LocalStorageService } from "../pages/user/services/local-storage.service";
import { UserWebService } from "../pages/user/services/user.web.service";
import { UserStorageService } from "../pages/user/services/user-storage.service";
import { AuthService } from "../../services/auth.service";

import { BaScrollPosition, BaSlimScroll, BaThemeRun } from "./directives";

import {
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
} from "./pipes";

import { BaMenuService, BaThemePreloader, BaThemeSpinner } from "./services";

import { EmailValidator, EqualPasswordsValidator } from "./validators";
import { ModuleWithProviders } from "@angular/core";

const NGA_COMPONENTS = [
  BaBackTop,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaPageTop,
  BaSidebar,
];

const NGA_DIRECTIVES = [BaScrollPosition, BaSlimScroll, BaThemeRun];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
];

const NGA_SERVICES = [
  BaThemePreloader,
  BaThemeSpinner,
  BaMenuService,
  LoginService,
  OAuthService,
  LocalStorageService,
  UserWebService,
  UserStorageService,
  AuthService,
];

const NGA_VALIDATORS = [EmailValidator, EqualPasswordsValidator];

@NgModule({
  declarations: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
})
export class NgaModule {
  static forRoot(): ModuleWithProviders<NgaModule> {
    return {
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        ...NGA_SERVICES,
      ],
    };
  }
}
