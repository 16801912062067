import { PermissionsEnum } from "../shared/enums/permissions.enum";

export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: '',
        data: {
          menu: {
            title: 'Cadastros',
            icon: '',
            selected: false,
            expanded: false,
            order: 0
          }
        },
        permission: PermissionsEnum.VISIVEL,
        children: [
          {
            path: '',
            data: {
              menu: {
                title: 'Organizações',
              }
            },
            permission: PermissionsEnum.VISIVEL,
            children: [
              {
                path: 'organizacao',
                data: {
                  menu: {
                    title: 'Organizações'
                  }
                },
                permission: PermissionsEnum.ORGANIZACAO_VISUALIZAR,
              },
              {
                path: 'usuario',
                data: {
                  menu: {
                    title: 'Usuários'
                  }
                },
                permission: PermissionsEnum.USUARIO_VISUALIZAR
              },
              {
                path: 'permissoes',
                data: {
                  menu: {
                    title: 'Permissões'
                  }
                },
                permission: PermissionsEnum.PERMISSAO_VISUALIZAR
              },
            ]
          },
          {
            path: '',
            data: {
              menu: {
                title: 'Relatórios',
              }
            },
            permission: PermissionsEnum.RELATORIO_GERAL_VISUALIZAR,
            children: [
              {
                path: 'relatorios/relatorio-antecipacao-aprovada',
                data: {
                  menu: {
                    title: 'Antecipações aprovadas'
                  }
                },
                permission: PermissionsEnum.RELATORIO_GERAL_VISUALIZAR
              },
              {
                path: 'relatorios/relatorio-curva-abc',
                data: {
                  menu: {
                    title: 'Curva ABC'
                  }
                },
                permission: PermissionsEnum.RELATORIO_GERAL_VISUALIZAR
              },
              {
                path: 'relatorios/relatorio-transacoes-aprovadas',
                data: {
                  menu: {
                    title: 'Transações aprovadas'
                  }
                },
                permission: PermissionsEnum.RELATORIO_GERAL_VISUALIZAR
              },
              {
                path: 'relatorios/relatorio-vendedores-dia',
                data: {
                  menu: {
                    title: 'Vendedores por dia'
                  }
                },
                permission: PermissionsEnum.RELATORIO_GERAL_VISUALIZAR
              },
              {
                path: 'relatorios/relatorio-vendedores-ativos',
                data: {
                  menu: {
                    title: 'Vendedores Ativos'
                  }
                },
                permission: PermissionsEnum.RELATORIO_GERAL_VISUALIZAR
              },
            ]
          },
          {
            path: 'antecipacao-transacao',
            data: {
              menu: {
                title: 'Antecipações de Transação',
              }
            },
            permission: PermissionsEnum.TRANSACAO_ANTECIPACAO_VISUALIZAR
          },
          {
            path: 'banners',
            data: {
              menu: {
                title: 'Banners',
              }
            },
            permission: PermissionsEnum.BANNER_VISUALIZE
          },
          {
            path: 'medico-plano-saude',
            data: {
              menu: {
                title: 'Convênios',
              }
            },
            permission: PermissionsEnum.MEDICO_PLANO_SAUDE_VISUALIZAR
          },
          {
            path: 'notificacao',
            data: {
              menu: {
                title: 'Notificações',
              }
            },
            permission: PermissionsEnum.NOTIFICACAO_VISUALIZAR
          },
          {
            path: 'lote',
            data: {
              menu: {
                title: 'Lotes',
              }
            },
            permission: PermissionsEnum.LOTE_VISUALIZAR
          },
          {
            path: 'vendedores',
            data: {
              menu: {
                title: 'Vendedores',
              }
            },
            permission: PermissionsEnum.VENDEDOR_VISUALIZAR
          },
          {
            path: 'transacoes',
            data: {
              menu: {
                title: 'Transações',
              }
            },
            permission: PermissionsEnum.TRANSACAO_VISUALIZAR
          },
          {
            path: 'recebiveis',
            data: {
              menu: {
                title: 'Recebíveis',
              }
            },
            permission: PermissionsEnum.TRANSACAO_RECEBIVEL_VISUALIZAR
          },
          {
            path: 'vendedor-plano-log',
            data: {
              menu: {
                title: 'Vendedor Plano Log',
              }
            },
            permission: PermissionsEnum.MEDICO_PLANO_SAUDE_LOGIN_VISUALIZAR
          },
          {
            path: 'referral',
            data: {
              menu: {
                title: 'Referral',
              }
            },
            permission: PermissionsEnum.ORGANIZACAO_VISUALIZAR
          },
          {
            path: 'split',
            data: {
              menu: {
                title: 'Split',
              }
            },
            permission: PermissionsEnum.TRANSACAO_SPLIT_VISUALIZAR
          },
          {
            path: 'terminais',
            data: {
              menu: {
                title: 'Terminais',
              }
            },
            permission: PermissionsEnum.CONTROLE_TERMINAL_VISUALIZAR
          },
          {
            path: 'conta-vinculada',
            data: {
              menu: {
                title: 'Conta Vinculada',
              }
            },
            permission: PermissionsEnum.CONTA_VINCULADA_VISUALIZAR
          },
          {
            path: 'antecipacao-lote',
            data: {
              menu: {
                title: 'Antecipação de Lote',
              }
            },
            permission: PermissionsEnum.ANTECIPACAO_LOTE_VISUALIZAR
          },
          {
            path: 'fidc',
            data: {
              menu: {
                title: 'FIDCs',
              }
            },
            permission: PermissionsEnum.FIDC_VISUALIZAR
          },
          {
            path: 'sincronizacao',
            data: {
              menu: {
                title: 'Sincronizações',
              }
            },
            permission: PermissionsEnum.VENDEDOR_SINCRONIZAR_STATUS
          },
          {
            path: 'plano-saude',
            data: {
              menu: {
                title: 'Plano de Saúde',
              }
            },
            permission: PermissionsEnum.PLANO_SAUDE_VISUALIZAR
          },
          {
            path: 'especialidade-medica',
            data: {
              menu: {
                title: 'Especialidade Médica',
              }
            },
            permission: PermissionsEnum.ESPECIALIDADE_VISUALIZAR
          },
          {
            path: 'log',
            data: {
              menu: {
                title: 'Logs',
              }
            },
            permission: PermissionsEnum.LOGS_VISUALIZAR
          },
        ]
      },
    ]
  }
];
