import { Injectable } from "@angular/core";
@Injectable()
export class CustomQueryEncoder {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
