import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DataSharingService {
  filterOrganizacao: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filterPlano: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filterDataInicio: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filterDataFim: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filterNumeroLote: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  setFilterOrganizacao(newData: any) {
    this.filterOrganizacao.next(newData);
  }

  setFilterPlano(newData: any) {
    this.filterPlano.next(newData);
  }

  setDataInicio(newData: any) {
    this.filterDataInicio.next(newData);
  }

  setDataFim(newData: any) {
    this.filterDataFim.next(newData);
  }

  setNumeroLote(newData: any) {
    this.filterNumeroLote.next(newData);
  }
}
