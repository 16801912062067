<div class="content-top clearfix">
  <h1 class="al-title" translate>{{ activePageTitle }}</h1>

  <ul class="breadcrumb al-breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/pages/dashboard" translate>{{'general.home'}}</a>
    </li>
    <li class="breadcrumb-item active" translate>{{ activePageTitle }}</li>
  </ul>
</div>
