import { throwError as observableThrowError, Observable } from "rxjs";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { RefreshTokenRequest } from "../../pages/user/models/user.requests";
import { environment } from "../../../environments/environment";
import { APIRoutes } from "../constants/api-routes.constant";
import { map, catchError } from "rxjs/operators";
import * as Sentry from "@sentry/browser";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class HttpInterceptor {
  // organizacaoId:string;
  private readonly ACCESS_TOKEN = "access_token";
  private readonly REFRESH_TOKEN = "refresh_token";

  constructor(
    private httpClient: HttpClient,
    private routerExtensions: Router
  ) {}

  get(url: string, options?: any): Observable<any> {
    return this.httpClient
      .get(url, options)
      .pipe(catchError((err, caught) => this.onCatch(err, caught)));
  }

  post(url: string, body: any, options?: any): Observable<any> {
    return this.httpClient
      .post(url, body, options)
      .pipe(catchError((err, caught) => this.onCatch(err, caught)));
  }

  put(url: string, body: any, options?: any): Observable<any> {
    return this.httpClient
      .put(url, body, options)
      .pipe(catchError((err, caught) => this.onCatch(err, caught)));
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    return this.httpClient
      .patch(url, body, options)
      .pipe(catchError((err, caught) => this.onCatch(err, caught)));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.httpClient
      .delete(url, options)
      .pipe(catchError((err, caught) => this.onCatch(err, caught)));
  }

  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    Sentry.captureException(error);
    let status = error.status;
    if (+status === 401) {
      this.isLogged().subscribe(
        (res) => {
          location.reload();
        },
        (err) => {
          window.localStorage.removeItem("user");
          this.routerExtensions.navigate(["login"]);
        }
      );
    }
    return observableThrowError(error);
  }

  getRefreshToken(refreshToken: string): Observable<any> {
    let data: RefreshTokenRequest = new RefreshTokenRequest(refreshToken);
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .post(environment.api.base_url + "/" + APIRoutes.OAUTH, data, { headers })
      .pipe(map((res: any) => res));
  }

  refreshLogin(refreshToken: string): Observable<any> {
    return this.getRefreshToken(refreshToken).pipe(
      map((res) => this.setTokens(res))
    );
  }

  setTokens(response: any) {
    this.setTokensOnLocalStorage(response.access_token, response.refresh_token);
    return response;
  }

  setTokensOnLocalStorage(access_token: string, refresh_token: string) {
    window.localStorage[this.ACCESS_TOKEN] = access_token;
    window.localStorage[this.REFRESH_TOKEN] = refresh_token;
  }

  isLogged(): Observable<any> {
    let tokens = this.getTokens();
    return this.refreshLogin(tokens.refreshToken);
  }

  getTokens(): { accessToken; refreshToken } {
    return {
      accessToken: window.localStorage[this.ACCESS_TOKEN],
      refreshToken: window.localStorage[this.REFRESH_TOKEN],
    };
  }
}
