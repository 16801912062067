import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, switchMap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { AuthenticationError } from "../models/user.errors";
import { OAuthService } from "./oauth.service";
import { UserStorageService } from "./user-storage.service";
import { UserWebService } from "./user.web.service";
import { User } from "../models/user.model";

@Injectable()
export class LoginService {
  private loggedIn: boolean = false;

  constructor(
    private oauthService: OAuthService,
    private userService: UserWebService,
    private userStorageService: UserStorageService
  ) {
    let tokens = this.oauthService.getTokens();
    this.loggedIn = !!tokens.accessToken && !!tokens.refreshToken;
  }

  isLogged(): Observable<any> {
    if (this.loggedIn) {
      let tokens = this.oauthService.getTokens();
      return this.refreshLogin(tokens.refreshToken);
    }
    return observableThrowError(
      new AuthenticationError("Invalid Credentials.")
    );
  }

  login(user: User): Observable<any> {
    return this.oauthService.getAccessToken(user).pipe(
      map((res) => this.setTokens(res)),
      switchMap((res) => this.userService.getSelf()),
      map((res) => this.loginSuccess(res)),
      catchError((res) => this.loginFailure(res))
    );
  }

  refreshLogin(refreshToken: string): Observable<any> {
    return this.oauthService
      .getRefreshToken(refreshToken)
      .pipe(map((res) => this.setTokens(res)));
  }

  logout() {
    this.oauthService.removeTokens();
  }

  private setTokens(response: any) {
    this.oauthService.setTokens(response.access_token, response.refresh_token);
    return response;
  }

  private loginFailure(error: any) {
    this.oauthService.removeTokens();
    this.userStorageService.removeUser();
    return observableThrowError(error);
  }

  private loginSuccess(response: any) {
    this.userStorageService.setUser(response);
    this.loggedIn = true;
    return response;
  }
}
