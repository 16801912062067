import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { FocusDirective } from "./directives/focus.directive";
import { ScrollDirective } from "./directives/scroll.directive";
import { HighlightPipe } from "./pipes/highlight.pipe";
import { DecimalDirective } from "./directives/decimal.directive";
import { DeleteModalComponent } from "./components/delete-modal.component";
import { LoadingIconComponent } from "./components/loading-icon.component";
import { HttpInterceptor } from "./interceptors/http-interceptor.service";
import { Router, RouterModule } from "@angular/router";
import { LoadingButtonComponent } from "./directives/loading-button.directive";
import { Ng2CompleterModule } from "ng2-completer";
import { TextMaskModule } from "angular2-text-mask";
import { OnlynumberDirective } from "./directives/onlynumber.directive";
import { WithoutPermission } from "../pages/without-permission/without-permission.component";
import { ErrorContainerComponent } from "./components/error-container.component";
import { IcpInput } from "./components/icp-input/icp-input.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "./components/confirm-modal.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    HttpClientModule,
    Ng2CompleterModule,
    RouterModule,
    TextMaskModule,
    NgbDatepickerModule,
  ],
  exports: [
    FormsModule,
    ScrollDirective,
    FocusDirective,
    HighlightPipe,
    DecimalDirective,
    DeleteModalComponent,
    LoadingButtonComponent,
    Ng2CompleterModule,
    LoadingIconComponent,
    OnlynumberDirective,
    WithoutPermission,
    ErrorContainerComponent,
    IcpInput,
    ConfirmModalComponent,
  ],
  declarations: [
    LoadingButtonComponent,
    ScrollDirective,
    FocusDirective,
    HighlightPipe,
    DecimalDirective,
    DeleteModalComponent,
    LoadingIconComponent,
    OnlynumberDirective,
    WithoutPermission,
    ErrorContainerComponent,
    IcpInput,
    ConfirmModalComponent,
  ],
  providers: [
    {
      provide: HttpInterceptor,
      useFactory: newHttp,
      deps: [Router, HttpClient],
    },
  ],
})
export class SharedModule {}

export function newHttp(routerExtensions: Router, httpClient: HttpClient) {
  return new HttpInterceptor(httpClient, routerExtensions);
}
