import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PermissionsService } from '../../../../../shared/services/permissions.service';

@Component({
  selector: 'ba-menu-item',
  templateUrl: './baMenuItem.html',
  styleUrls: ['./baMenuItem.scss']
})
export class BaMenuItem {

  @Input() menuItem:any;
  @Input() child:boolean = false;

  @Output() itemHover = new EventEmitter<any>();
  @Output() toggleSubMenu = new EventEmitter<any>();
  @Output('onItemClick') onItemClick = new EventEmitter<any>();

  constructor(private permissionsService: PermissionsService) {}

  public onHoverItem($event: any, item?: any):void {
    if (item) {
      $event.item = item;
    }

    this.itemHover.emit($event);
  }

  public onToggleSubMenu($event: any, item: any):boolean {
    $event.item = item;
    this.toggleSubMenu.emit($event);
    return false;
  }

  public onMenuItemClick() {
    this.onItemClick.emit(true);
  }

  public hasPermission(permission: string): boolean {
    return this.permissionsService.hasPermission(permission);
  }
}
