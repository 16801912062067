import { User } from './user.model';
import { environment } from '../../../../environments/environment';

export class AccessTokenRequest{
    username: string;
    password: string;
    grant_type: string;
    client_id: string;
    client_secret: string;

    constructor(
        username:string, password:string,
        grant_type="password", client_id=environment.api.client_id, client_secret=environment.api.client_secret
    ){
        this.username = username;
        this.password = password;
        this.grant_type = grant_type;
        this.client_id = client_id;
        this.client_secret = client_secret;
    }
}

export class RefreshTokenRequest{
    grant_type: string;
    client_id: string;
    client_secret: string;
    refresh_token: string;

    constructor(
        refresh_token:string,
        grant_type="refresh_token", client_id=environment.api.client_id, client_secret=environment.api.client_secret
    ){
        this.refresh_token = refresh_token;
        this.grant_type = grant_type;
        this.client_id = client_id;
        this.client_secret = client_secret;
    }
}

export class PasswordResetRequest{
    token: string;
    email: string;
    password: string;
    password_confirmation: string;

    constructor(
        token: string, email: string, password: string, password_confirmation: string
    ){
        this.token = token;
        this.email = email;
        this.password = password;
        this.password_confirmation = password_confirmation;
    }
}

export class UserOrganizationCreateRequest{
    url: string;
    nome: string;
    user: User;

    constructor(url:string, nome:string, user:User){
        this.url = url;
        this.nome = nome;
        this.user = user;
    }
}
