import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { routing } from "./pages.routing";
import { NgaModule } from "../theme/nga.module";

import { Pages } from "./pages.component";

import { SharedModule } from "../shared/shared.module";
import { Oauth2TokenService } from "../../services/oauth2-token.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { DataSharingService } from "./medicinae/lote/services/data-sharing.service";

@NgModule({
  imports: [CommonModule, NgaModule, routing, SharedModule],
  declarations: [Pages],
  exports: [],
  providers: [Oauth2TokenService, LocalStorageService, DataSharingService],
})
export class PagesModule {}
