import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';

// App is our top level component
import * as Sentry from "@sentry/browser";
import { App } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { PagesModule } from './pages/pages.module';
import { AuthGuardRouterService } from '../services/auth-guard-router.service';

import './shared/utils/rxjs-operators';
import { environment } from '../environments/environment';
import { hasPermissionService } from './shared/services/has.permission.service';
import {CustomQueryEncoder} from "./shared/services/custom-query-encoder.service";
import { HttpClientModule } from '@angular/common/http';

if (environment.name !== 'local') {
  Sentry.init({
    dsn: "https://d36b970e6ff44875960295990c448133@o253472.ingest.sentry.io/5290630",
    environment: environment.name
  });
}

if (localStorage && localStorage["user"] && environment.name !== 'local') {
  try {
    const user = JSON.parse(localStorage["user"]);
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id,
        username: user.name,
        email: user.email
      });
    });
  } catch (e) {
    console.error("Error setting Sentry user", e);
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.error('error', error);
    if (environment.name !== 'local') {
      const eventId = Sentry.captureException(error.originalError || error);

      let user = null;
      try {
        const localUser = JSON.parse(localStorage["user"]);
        user = {
          id: localUser.id,
          name: localUser.name,
          email: localUser.email
        };
      } catch (e) {
        console.error("Error setting Sentry user", e);
      }
    }
  }
}

// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
  AuthGuardRouterService
];

export type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [
    App
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    NgbModule,
    PagesModule,
    routing
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    hasPermissionService,
    APP_PROVIDERS,
    CustomQueryEncoder
  ]
})

export class AppModule {

  constructor(public appState: AppState) {
  }
}
