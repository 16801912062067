import { OAuthService } from '../../../pages/user/services/oauth.service';
import { HttpHeaders } from '@angular/common/http';

export abstract class AuthenticatedWebService {

    protected headers: HttpHeaders;

    constructor(private oauthService:OAuthService) {
        let accessToken = this.oauthService.getTokens().accessToken;
        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        });
    }
}
