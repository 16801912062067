import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

import { LocalStorageService } from "./local-storage.service";
import { Oauth2TokenService } from "./oauth2-token.service";
import { HttpInterceptor } from "../app/shared/interceptors/http-interceptor.service";
import { HttpHeaders } from "@angular/common/http";

const USER_KEY = "user";
@Injectable()
export class AuthService {
  public check: Boolean = false;
  private urlLogin: string;
  private urlUser: string;

  public user: any = { name: "" };

  constructor(
    private oauth2Token: Oauth2TokenService,
    private http: HttpInterceptor,
    private localStorage: LocalStorageService,
  ) {
    this.check = this.oauth2Token.token ? true : false;
    let userLocalStorage = this.localStorage.getObject(USER_KEY);
    this.user = userLocalStorage ? userLocalStorage : { name: "" };
    this.urlLogin = environment.api.base_url + "/oauth/token";
    this.urlUser = environment.api.base_url + "/api/v1/user/self";
  }

  getHeaders() {
    const accessToken = this.oauth2Token.getTokens().accessToken;

    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    });
  }

  async login({ email, password }) {
    return this.http
      .post(this.urlLogin, {
        username: email,
        password: password,
        grant_type: "password",
        client_id: environment.api.client_id,
        client_secret: environment.api.client_secret,
      })
      .toPromise()
      .then((response) => {
        this.check = true;
        this.oauth2Token.token = response.access_token;
        this.getUser();
        return response;
      });
  }

  logout() {
    this.oauth2Token.token = null;
    this.check = false;
    this.localStorage.remove(USER_KEY);
  }

  private getUser() {
    return this.http
      .get(this.urlUser, { headers: this.getHeaders() })
      .toPromise()
      .then((response) => {
        this.user = response;
        this.localStorage.setObject(USER_KEY, this.user);
      });
  }
}
