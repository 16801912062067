import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { User } from '../models/user.model';
import { APIRoutes } from '../../../shared/constants/api-routes.constant';
import { BaseWebService } from '../../../shared/base/services/base.web.service';
import { OAuthService } from './oauth.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserWebService extends BaseWebService<User> {
    constructor(http:HttpInterceptor, oauthService:OAuthService){
        super(APIRoutes.USER_LIST, http, oauthService);
    }

    newElement(data:any):User {
        return new User(data.id, data.name, data.email, null, null, data.permissions);
    }

    getHeaders() {
      const accessToken = this['oauthService'].getTokens().accessToken;

      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      })
    }

    getSelf(){
        const url = this.BASE_URL + APIRoutes.SELF_USER + '/?include=permissao';
        return this.http
            .get(url, { headers: this.getHeaders() }).pipe(
            map((res: any) => res.data[0]),
            map((res) => this.newElement(res)),);
    }

    getAdmin(){
        const url = this.BASE_URL + APIRoutes.ADMIN;

        return this.http
            .get(url, { headers: this.getHeaders() })
            .pipe(
                map((res) => {
                    const obj = res;

                    if (!obj.data) {
                        throw new Error('O usuário não tem permissão para logar no painel administrador.');
                    }

                    return obj.data;
                })
            );
    }

}
