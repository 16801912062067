import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import {
  AccessTokenRequest,
  RefreshTokenRequest,
} from "../models/user.requests";
import { HttpInterceptor } from "../../../shared/interceptors/http-interceptor.service";
import { LocalStorageService } from "./local-storage.service";
import { User } from "../models/user.model";
import { APIRoutes } from "../../../shared/constants/api-routes.constant";
import { environment } from "../../../../environments/environment";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class OAuthService {
  private readonly ACCESS_TOKEN = "access_token";
  private readonly REFRESH_TOKEN = "refresh_token";

  private headers: HttpHeaders;

  constructor(
    private http: HttpInterceptor,
    private localStorage: LocalStorageService
  ) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  getRefreshToken(refreshToken: string): Observable<any> {
    if (!refreshToken) throw new Error("Refresh token is required");

    let data: RefreshTokenRequest = new RefreshTokenRequest(refreshToken);
    return this.http
      .post(environment.api.base_url + "/" + APIRoutes.OAUTH, data, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res));
  }

  getAccessToken(user: User): Observable<any> {
    if (!user.email) throw new Error("Email required.");
    if (!user.password) throw new Error("Password required.");

    let data: AccessTokenRequest = new AccessTokenRequest(
      user.email,
      user.password
    );

    return this.http
      .post(environment.api.base_url + "/" + APIRoutes.OAUTH, data, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res));
  }

  getTokens(): { accessToken: any; refreshToken: any } {
    return {
      accessToken: this.localStorage.get(this.ACCESS_TOKEN),
      refreshToken: this.localStorage.get(this.REFRESH_TOKEN),
    };
  }

  removeTokens() {
    this.localStorage.remove(this.ACCESS_TOKEN);
    this.localStorage.remove(this.REFRESH_TOKEN);
  }

  setTokens(access_token: string, refresh_token: string) {
    this.localStorage.set(this.ACCESS_TOKEN, access_token);
    this.localStorage.set(this.REFRESH_TOKEN, refresh_token);
  }
}
