import { map } from "rxjs/operators";

import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";

import { OAuthService } from "../../../pages/user/services/oauth.service";
import { AuthenticatedWebService } from "./authenticated.web.service";
import { HttpInterceptor } from "../../interceptors/http-interceptor.service";
import { Identifiable } from "../models/identifiable";
import { environment } from "../../../../environments/environment";

export abstract class BaseWebService<T> extends AuthenticatedWebService {
  public readonly BASE_URL: string = environment.api.base_url + "/api/v1/";

  protected route: string;

  constructor(
    url: string,
    protected http: HttpInterceptor,
    oauthService: OAuthService
  ) {
    super(oauthService);
    this.route = url;
  }

  abstract newElement(data: any): T;

  create(data: any): Observable<T> {
    let url = this.BASE_URL + this.route;

    return this.http.post(url, data, { headers: this.headers }).pipe(
      map((res: any) => res.data),
      map((res) => this.newElement(res))
    );
  }

  update(data: Identifiable): Observable<T> {
    let url = this.BASE_URL + this.route + "/" + data.id;

    return this.http.put(url, data, { headers: this.headers }).pipe(
      map((res: any) => res.data),
      map((res) => this.newElement(res))
    );
  }

  get(params: HttpParams): Observable<T> {
    let url = this.BASE_URL + this.route;

    return this.http.get(url, { params: params, headers: this.headers }).pipe(
      map((res: any) => res.data),
      map((res) => this.newElement(res))
    );
  }

  list(params?: HttpParams): Observable<Array<T>> {
    let url = this.BASE_URL + this.route;

    return this.http.get(url, { params: params, headers: this.headers }).pipe(
      map((res: any) => res.data),
      map((res) => {
        let result = Array<T>();
        res.forEach((u: any) => {
          result.push(this.newElement(u));
        });
        return result;
      })
    );
  }

  delete(id: any): Observable<T> {
    let url = this.BASE_URL + this.route + "/" + id;

    return this.http.delete(url, { headers: this.headers }).pipe(
      map((res: any) => res.data),
      map((res) => this.newElement(res))
    );
  }
}
