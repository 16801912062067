import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { HttpInterceptor } from "../app/shared/interceptors/http-interceptor.service";
import { Observable } from "rxjs/Observable";
import { environment } from "../environments/environment";
import {
  RefreshTokenRequest,
  AccessTokenRequest,
} from "../app/pages/user/models/user.requests";
import { User } from "../app/pages/user/models/user.model";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class Oauth2TokenService {
  private readonly ACCESS_TOKEN = "access_token";
  private readonly REFRESH_TOKEN = "refresh_token";
  private headers: HttpHeaders;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpInterceptor
  ) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
  }

  getRefreshToken(refreshToken: string): Observable<any> {
    if (!refreshToken) throw new Error("Refresh token is required");

    let data: RefreshTokenRequest = new RefreshTokenRequest(refreshToken);
    return this.http
      .post(environment.api.base_url + "/oauth/token", data, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res));
  }

  getAccessToken(user: User): Observable<any> {
    if (!user.email) throw new Error("Email required.");
    if (!user.password) throw new Error("Password required.");

    let data: AccessTokenRequest = new AccessTokenRequest(
      user.email,
      user.password
    );
    return this.http
      .post(environment.api.base_url + "/oauth/token", data, {
        headers: this.headers,
      })
      .pipe(map((res: any) => res));
  }

  getTokens(): { accessToken: any; refreshToken: any } {
    return {
      accessToken: this.localStorage.get(this.ACCESS_TOKEN),
      refreshToken: this.localStorage.get(this.REFRESH_TOKEN),
    };
  }

  setTokens(access_token: string, refresh_token: string) {
    this.localStorage.set(this.ACCESS_TOKEN, access_token);
    this.localStorage.set(this.REFRESH_TOKEN, refresh_token);
  }

  removeTokens() {
    this.localStorage.remove(this.ACCESS_TOKEN);
    this.localStorage.remove(this.REFRESH_TOKEN);
  }

  get token() {
    return this.localStorage.get(this.ACCESS_TOKEN);
  }

  set token(value) {
    value
      ? this.localStorage.set(this.ACCESS_TOKEN, value)
      : this.localStorage.remove(this.ACCESS_TOKEN);
  }
}
