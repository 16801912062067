import { Injectable } from '@angular/core';
import { UserStorageService } from '../../pages/user/services/user-storage.service';

@Injectable()
export class hasPermissionService {

    constructor(
        private userStorageService: UserStorageService,
    ){}

    hasPermission(permission): boolean{
        return this.userStorageService.getUser().permissions.includes(permission);
    }

}