import { Identifiable } from '../../../shared/models/identifiable.model';

export class User implements Identifiable{
    id:string;
    name:string;
    email:string;
    password:string;
    password_confirmation:string;
    permissions:string[];

    constructor(
        id?:string, name?:string, email?:string, password?:string, password_confirmation?:string, permissions?:string[])
    {
        this.id = id;
        this.name = name;
        this.email = email;
        this.password = password;
        this.password_confirmation = password_confirmation;
        this.permissions = permissions;
    }
}