import { Routes, RouterModule } from "@angular/router";
import { Pages } from "./pages.component";

// Services
import { ModuleWithProviders } from "@angular/core";

// noinspection TypeScriptValidateTypes
const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "pages",
    component: Pages,
    children: [
      {
        path: "plano-saude",
        loadChildren: () =>
          import("./medicinae/plano-saude/plano-saude.module").then(
            (m) => m.PlanoSaudeModule
          ),
      },
      {
        path: "log",
        loadChildren: () =>
          import("./medicinae/log/log.module").then((m) => m.LogModule),
      },
      {
        path: "especialidade-medica",
        loadChildren: () =>
          import(
            "./medicinae/especialidade-medica/especialidade-medica.module"
          ).then((m) => m.EspecialidadeMedicaModule),
      },
      {
        path: "organizacao",
        loadChildren: () =>
          import("./medicinae/organizacao/organizacao.module").then(
            (m) => m.OrganizacaoModule
          ),
      },
      {
        path: "usuario",
        loadChildren: () =>
          import("./medicinae/usuario/usuario.module").then(
            (m) => m.UsuarioModule
          ),
      },
      {
        path: "antecipacao-lote",
        loadChildren: () =>
          import("./medicinae/emprestimo-list/emprestimo.module").then(
            (m) => m.EmprestimoModule
          ),
      },
      {
        path: "sincronizacao",
        loadChildren: () =>
          import("./medicinae/sincronizacao/sincronizacao.module").then(
            (m) => m.SincronizacaoModule
          ),
      },
      {
        path: "lote",
        loadChildren: () =>
          import("./medicinae/lote/lote.module").then((m) => m.LoteModule),
      },
      {
        path: "medico-plano-saude",
        loadChildren: () =>
          import(
            "./medicinae/medico-plano-saude/medico-plano-saude.module"
          ).then((m) => m.MedicoPlanoSaudeModule),
      },
      {
        path: "transacoes",
        loadChildren: () =>
          import("./medicinae/transacoes/transacoes.module").then(
            (m) => m.TransacoesModule
          ),
      },
      {
        path: "recebiveis",
        loadChildren: () =>
          import("./medicinae/recebiveis/recebiveis.module").then(
            (m) => m.RecebiveisModule
          ),
      },
      {
        path: "split",
        loadChildren: () =>
          import("./medicinae/split/split.module").then((m) => m.SplitModule),
      },
      {
        path: "terminais",
        loadChildren: () =>
          import("./medicinae/terminais/terminais.module").then(
            (m) => m.TerminaisModule
          ),
      },
      {
        path: "vendedores",
        loadChildren: () =>
          import("./medicinae/vendedores/vendedores.module").then(
            (m) => m.VendedoresModule
          ),
      },
      {
        path: "referral",
        loadChildren: () =>
          import("./medicinae/referral/referral.module").then(
            (m) => m.ReferralModule
          ),
      },
      {
        path: 'vendedor-plano-log',
        loadChildren: () =>
          import(
            './medicinae/vendedor-plano-log/vendedor-plano-log.module'
          ).then((m) => m.VendedorPlanoLogModule),
      },
      {
        path: 'permissoes',
        loadChildren: () =>
          import('./medicinae/permissoes/permissoes.module').then(
            (m) => m.PermissoesModule
          ),
      },
      {
        path: "notificacao",
        loadChildren: () =>
          import("./medicinae/notificacao/notificacao.module").then(
            (m) => m.NotificacaoModule
          ),
      },
      {
        path: 'relatorios',
        loadChildren: () =>
          import('./medicinae/relatorios/relatorios.module').then(
            (m) => m.RelatoriosModule
          ),
      },
      {
        path: 'antecipacao-transacao',
        loadChildren: () =>
          import(
            './medicinae/antecipacao-transacao/antecipacao-transacao.module'
          ).then((m) => m.AntecipacaoTransacaoModule),
      },
      {
        path: "conta-vinculada",
        loadChildren: () =>
          import("./medicinae/conta-vinculada/conta-vinculada.module").then(
            (m) => m.ContaVinculadaModule
          ),
      },
      {
        path: 'fidc',
        loadChildren: () =>
          import('./medicinae/fidc/fidc.module').then((m) => m.FidcModule),
      },
      {
        path: "banners",
        loadChildren: () =>
          import("./medicinae/banners/banners.module").then(
            (m) => m.BannersModule
          ),
      },
    ],
  },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
